import { AfterViewInit, Component, Injectable, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { SettingsService } from '../service/settings.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateContentTypeDialogComponent } from '../create-content-type-dialog/create-content-type-dialog.component';
import { DuplicatePassTemplateDialogComponent } from '../duplicate-pass-template-dialog/duplicate-pass-template-dialog.component';
import { DeleteContentTypesDialogComponent } from '../delete-content-types-dialog/delete-content-types-dialog.component';
import { CommentsService } from '../service/comments.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { ContentType } from '../../../../backend/src/contentType.dto';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { PassDefinitionsService } from '../service/pass-definitions.service';
import { MatSort } from '@angular/material/sort';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ContentTypeService } from '../service/content-type.service';
import { LocalStorageService } from '../service/local-storage-service.service';
import { NgDialogAnimationService } from 'ng-dialog-animation';
import { ContentTypesViewDialogComponent } from '../content-types-view-dialog/content-types-view-dialog.component';
import { AdvancedExperienceViewDialogComponent } from '../advanced-experience-view-dialog/advanced-experience-view-dialog.component';
import { saveAs } from "file-saver";
import * as JSZip from "jszip";
import { removeUndefined, supportUrlStructure } from '../../../../backend/utils/object';
import { deepCopy } from '@firebase/util';
import { DownloadService } from '../service/download.service';
import { HttpClient } from '@angular/common/http';
import { Template } from '../../../../backend/src/template.dto';
import { UploadService } from '../service/upload.service';
import { isDateTimeGetTimeSeconds } from '../../../../backend/src/filter';

@Injectable()
export class MyCustomPaginatorIntl implements MatPaginatorIntl {
  changes = new Subject<void>();
  constructor(private translate: TranslateService){}

  firstPageLabel = this.translate.instant("first_page");
  itemsPerPageLabel = this.translate.instant("items_page");
  lastPageLabel =this.translate.instant("last_page")
  nextPageLabel = this.translate.instant("next_page");
  previousPageLabel = this.translate.instant("previous_page");

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return this.translate.instant("page1_1");
    }
    const amountPages = Math.ceil(length / pageSize);
     
    return this.translate.instant("page_of_1") + (page + 1) +this.translate.instant("page_of_2") + amountPages;
  }
}

@Component({
  selector: 'app-content-types-list',
  templateUrl: './content-types-list.component.html',
  styleUrls: ['./content-types-list.component.css'],
  providers: [{provide: MatPaginatorIntl, useClass: MyCustomPaginatorIntl}],
})

export class ContentTypesListComponent implements OnInit, AfterViewInit{
  allContents:any
  emptyList=false
  deleteMode=true
  list:any
  idToShow:any
  contentTypeUserRights_delete:any
  contentData:ContentType
  searchText = ''; 
/*   displayedColumns: string[] = ['coverImage', 'displayName', 'creationDate', 'timeStamp','deleteContentType']; */
  displayedColumns: string[] = ['coverImage','displayName','comment','creationDate', 'timeStamp','deleteContentType'];
  listdata= new MatTableDataSource()
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  paginatorSize = this.settingsService.paginator$
  contentTypeUserRights_create:any
  contextId :any
  advancedExperiencesData:any
  allPassDefinitions:any
  
  importedPassNewID$:Subject<string> = new Subject<string>();
  importedContentypeID$:Subject<string> = new Subject<string>();
  importedPassFilesToUpload$:Subject<any> = new Subject<any>();
  importedZip$:Subject<any> = new Subject<any>();
  importedFiles$:Subject<any> = new Subject<any>();
  importedFilesMedia$:Subject<any> = new Subject<any>();

  constructor(private settingsService: SettingsService,
    public dialog: MatDialog,
    private commentService:CommentsService,
    private router: Router,
    private translate: TranslateService,
    private passService: PassDefinitionsService,
    private contenttypeService: ContentTypeService,
    private localStorageService:LocalStorageService,
    private changeDetectorRef: ChangeDetectorRef,
    public dialogA: NgDialogAnimationService,
    private http: HttpClient,
    private uploadService:UploadService
    ){}
  

  ngOnInit(): void {
    this.allPassDefinitions = this.settingsService.observeContextsPassDefinitionsFirestoreWithId(this.settingsService.contextId$)
    this.advancedExperiencesData = this.settingsService.observeAdvancedExperiencesFirestore()
    this.contentTypeUserRights_create = this.settingsService.userRolesRights$['contentTypes']['C']
    this.contentTypeUserRights_delete = this.settingsService.userRolesRights$['contentTypes']['D']
    if(!this.contentTypeUserRights_create)
    document.getElementById("btn-create").style.backgroundColor='#e0e0e0'
    this.commentService.progressSpin.emit(true)
    this.contextId = this.settingsService.contextId$

    setTimeout(() => {
      this.getContentTypes()
    }, 500);
  }

  loadTablePreferences(): void {
    const preferences = this.localStorageService.get('contentTypeTablePreferences');
    if (preferences) {
      this.paginator.pageSize = preferences.pageSize;
      this.sort.active = preferences.sortActive;
      this.sort.direction = preferences.sortDirection;
      this.paginator.pageIndex = preferences.pageIndex;
    } else {
      this.sort.active = 'timeStamp';
      this.sort.direction = 'desc';
    }
    this.changeDetectorRef.detectChanges();
  }

  storeTablePreferences(): void {
    const preferences = {
      pageSize: this.paginator.pageSize,
      sortActive: this.sort.active,
      sortDirection: this.sort.direction,
      pageIndex: this.paginator.pageIndex
    };
    this.localStorageService.set('contentTypeTablePreferences', preferences);
  }

  backTo(){
    this.router.navigate(['/home', this.settingsService.contextId$, 'contentTypes'])
  }

  activeDelete(contentData: ContentType) {
    this.contentData = contentData;
    let dialogRef = this.dialog.open(DeleteContentTypesDialogComponent, {
      data: {
        values: contentData,
      },
    });
  
    dialogRef.afterClosed().subscribe((result) => {
      if (result[0]) {
        this.commentService.progressSpin.emit(true)

        this.shorTableAgain()
        this.resetList();
      } else {
        const message = this.translate.instant("SnackBarConstants.DELETE_DISMISS");
        this.commentService.addSnackBar.emit(message);
      }
    });
  }

  shorTableAgain(){
    this.listdata.paginator = this.paginator;
    this.listdata.sort = this.sort;
    setTimeout(() => {
      this.listdata.filter = " ";
    this.commentService.progressSpin.emit(false)
      
    }, 500);
  }

  ngAfterViewInit(): void {
    this.listdata.paginator = this.paginator;
    this.listdata.sort = this.sort;

    this.paginator.page.subscribe(() => this.storeTablePreferences());
    this.sort.sortChange.subscribe(() => this.storeTablePreferences());

    this.loadTablePreferences();

    setTimeout(() => {
      if (this.allContents && this.allContents.length != 0) {
        this.listdata.paginator = this.paginator;
        const preferences = this.localStorageService.get('contentTypeTablePreferences');
        if (preferences) {
          this.paginator.pageIndex = preferences.pageIndex;
          this.paginator._changePageSize(this.paginator.pageSize);
        } else {
          this.listdata.paginator.firstPage();
        }
        this.commentService.progressSpin.emit(false);
      } else {
        this.commentService.progressSpin.emit(false);
      }
      this.listdata.filter = " ";
      this.commentService.progressSpin.emit(false)
    }, 500);
  }
  getContentTypes() {
    try {
      this.settingsService.getPreformatedContentTypes().subscribe(allContents => {
        this.allContents = allContents;
    
        this.listdata = new MatTableDataSource<any>(this.allContents);
    
        if (this.allContents.length == 0) {
          this.emptyList = true;
        }
        this.shorTableAgain();
      });
    } catch(error) {
      console.log(error);
      this.commentService.progressSpin.emit(false);
    }
  }

  openDuplicatePassDialog(row){
    if(row.passTemplateId){
      let dialogRef = this.dialog.open(DuplicatePassTemplateDialogComponent, {
        data: {
          passTemplateId: row.passTemplateId,
          oldDisplayName: row.displayName,
          contextid: this.contextId,
          contentTypeId: row.id
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result[0]){
          this.resetList()
        }
      });
    }
    else{
      const message = this.translate.instant("SnackBarConstants.DUPLICATION_NOT_POSSIBLE")
      this.commentService.addSnackBar.emit(message)
    }
  
  }

  openDialogNewContentType(){   
    if(this.contentTypeUserRights_create){
        let dialogRef= this.dialog.open(CreateContentTypeDialogComponent, {
        
      });
    
      dialogRef.afterClosed().subscribe(result => {
        if(result[0]){
          this.getContentTypes()
          try{
            this.commentService.progressSpin.emit(true)
            const newRoute = result[2]
            this.router.navigateByUrl(newRoute)
            this.commentService.progressSpin.emit(false)
          }catch(error){
            console.log(error)
            this.commentService.progressSpin.emit(false)
          }
        }
      });
    }else{
      const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED_CREATE")
      this.commentService.addSnackBar.emit(message)
    }
    
  }

  isDate(element){
    let dateValue
    dateValue = element
    if(element['nanoseconds'] || element['seconds']){
      const NS_TO_MS_MULTIPLIER = 1/1000000
      const SEC_TO_MS_MULTIPLIER = 1000
      const timestampInMilliseconds = element['seconds'] * SEC_TO_MS_MULTIPLIER + element['nanoseconds'] * NS_TO_MS_MULTIPLIER
      const date = new Date(timestampInMilliseconds)
      element= date
      dateValue = date.toDateString()
    }
   return dateValue
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.listdata.filter = filterValue.trim().toLowerCase();

    if (this.listdata.paginator) {
      this.listdata.paginator.firstPage();
    }
  }

  async setRouteLink(contentype){
    this.commentService.progressSpin.emit(true)
    if(contentype.passTemplateId){
      this.router.navigate(['home', this.contextId,'contentTypes', contentype.id, 'passTemplate', contentype.passTemplateId])
      this.commentService.progressSpin.emit(false)

    }else{
      try{
        // fallback for contenttypes without pass templates
        const passid = await this.passService.createPassDefinition(contentype.displayName, contentype.id, this.settingsService.languagesContexts$)
        const data = { id: contentype.id, passTemplateId: passid}
        const newcontent = new ContentType()
        newcontent.setFromAny(data)

        await this.contenttypeService.updateContentType(newcontent, contentype)
        this.commentService.progressSpin.emit(false)
        this.router.navigate(['home',this.contextId, 'contentTypes', contentype.id, 'passTemplate', passid])
      }catch(error){
        console.log(error)
      }
    }
  }

  async resetList(){
    try{
      this.commentService.progressSpin.emit(true)
      await this.settingsService.observeContextsContentTypesFirestoreWithId(this.settingsService.contextId$)
      this.commentService.progressSpin.emit(false)

    }catch(error){
      this.commentService.progressSpin.emit(false)
    }

  }

  openContentTypeView(row):void{
    let dialogRef= this.dialogA.open(ContentTypesViewDialogComponent, {
      data:{
        contentType: row,//this.contentType,
        template: row.passTemplateId,//this.templateForm.value,
        contextId: this.settingsService.contextId$,
        contentTypeId: row.id//this.contentTypeId
      },
      panelClass: 'custom-dialog-container',
      position: { right: '0'},
      animation: {
        to: "left",
        incomingOptions: {
          keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
        },
        outgoingOptions: {
          keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
        }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[0]){
        // this.contentType = result[1]
        // this.updateContentType(result[1])
      }
    });
  }

  openDialogCoverImage(row):void{
    if(row){//this.contentType
        let dialogRef= this.dialog.open(AdvancedExperienceViewDialogComponent, {
          disableClose: true,
          data:{
            //template: this.templateForm.value,
            contextId: this.settingsService.contextId$,
            contentTypeId: row.id,//this.contentTypeId,
            contentType: row//this.contentType
          }
        });
    
        dialogRef.afterClosed().subscribe(result => {
          // if(result){
          //   this.getContentTypeId()  
          // }
        });
    }
  }


  async exportPassTamplate(content){
    let row = deepCopy(content)
    this.commentService.progressSpin.emit(true)

    if(row.passTemplateId){
      const passId = row.passTemplateId
      let passdata = await this.settingsService.getPassDefinitionId(row.passTemplateId).then(value => {
        let imagesToExport={}
        let exportImages=[]
        let fileImages

        // try{
          if(value){

            if(value.widgets){
              const widgets = value.widgets
              widgets.forEach(widget => {
                const type = widget.type
                const id = widget.id
                const fileName = id + '_type'
                switch(type){
                  case 'billboardImage':{
                    if(widget.url){
                      const img = deepCopy(supportUrlStructure(widget.url, passId ,this.settingsService.contextId$))
                      if(img){
                        imagesToExport[id] = img
                        exportImages.push([id, img,type])
                      }
                    }
  
                    if(widget.linkURL){
                      let linkurl = deepCopy(supportUrlStructure(widget.linkURL, passId,this.settingsService.contextId$))
                      if(linkurl){
                        imagesToExport['link-'+id] = linkurl
                        exportImages.push(['link-'+id,linkurl,type])
                      }
                    }
                    break;
                  }
  
                  case 'billboardVideo':{
                    if(widget.url){
                      const img = deepCopy(supportUrlStructure(widget.url, passId,this.settingsService.contextId$))
                      if(img){
                        imagesToExport[id] = img
                        exportImages.push([id, img,type])
                      }
                    }
                    break;
                  }
                  case 'text':{
                    if(widget.image && widget.image.url){
                      const img = deepCopy(supportUrlStructure(widget.image.url, passId,this.settingsService.contextId$))
                      if(img){
                        // imagesToExport[id+'_'+type] = img
                        // exportImages.push([id+'_'+type, img,type])
                        imagesToExport[id] = img
                        exportImages.push([id, img,type])
                      }
                    }
  
                    if(widget.image && widget.image.linkURL){
                      let linkurl = deepCopy(supportUrlStructure(widget.image.linkURL, passId,this.settingsService.contextId$))
                      if(linkurl){
                        imagesToExport['link-'+id] = linkurl
                        exportImages.push(['link-'+id, linkurl,type])
                      }
                    }
                    break;
                  }
  
                  case 'imagesHorizontal':{
                    if(widget.images){
                      const images = widget.images
                      images.forEach((image,imageIndex) => {
                        if(image.url){
                          let img = deepCopy(supportUrlStructure(image.url, passId,this.settingsService.contextId$))
                          if(img){
                            imagesToExport[id+'.'+imageIndex] = img
                            exportImages.push([id+'.'+imageIndex, img,type])
                          }
                        }
  
                        if(image.linkURL){
                          let linkurl = deepCopy(supportUrlStructure(image.linkURL, passId,this.settingsService.contextId$))
                          if(linkurl){
                            imagesToExport['link-'+id+'.'+imageIndex] = linkurl
                            exportImages.push(['link-'+id+'.'+imageIndex, linkurl,type])
                          }
                        }
                      });
                    }
                    break;
                  }
                  case 'imageBlocks':{
                    if(widget.images){
                      const images = widget.images
                      images.forEach((image,imageIndex) => {
                        if(image.url){
                          let img = deepCopy(supportUrlStructure(image.url, passId,this.settingsService.contextId$))
                          if(img){
                            imagesToExport[id+'.'+imageIndex] = img
                            exportImages.push([id+'.'+imageIndex, img,type])
                          }
                        }
  
                        if(image.linkURL){
                          let linkurl = deepCopy(supportUrlStructure(image.linkURL, passId,this.settingsService.contextId$))
                          if(linkurl){
                            imagesToExport['link-'+id+'.'+imageIndex] = linkurl
                            exportImages.push(['link-'+id+'.'+imageIndex, linkurl,type])
                          }
                        }
                      });
                    }
                    break;
                  }
  
                  case 'images':{
                    if(widget.images){
                      const images = widget.images
                      images.forEach((image,imageIndex) => {
                        if(image.url){
                          let img = deepCopy(supportUrlStructure(image.url, passId,this.settingsService.contextId$))
                          if(img){
                            imagesToExport[id+'.'+imageIndex] = img
                            exportImages.push([id+'.'+imageIndex, img,type])
                          }
                        }
  
                        if(image.linkURL){
                          let linkurl = deepCopy(supportUrlStructure(image.linkURL, passId,this.settingsService.contextId$))
                          if(linkurl){
                            imagesToExport['link-'+id+'.'+imageIndex] = linkurl
                            exportImages.push(['link-'+id+'.'+imageIndex, linkurl,type])
                          }
                        }
                      });
                    }
                    break;
                  }
  
                  case 'image':{
                    if(widget.url){
                      const img = deepCopy(supportUrlStructure(widget.url, passId,this.settingsService.contextId$))
                      if(img){
                        imagesToExport[id] = img
                        exportImages.push([id, img,type])
                      }
                    }
                    if(widget.linkURL){
                      let linkurl = deepCopy(supportUrlStructure(widget.linkURL, passId,this.settingsService.contextId$))
                      if(linkurl){
                        imagesToExport['link-'+id] = linkurl
                        exportImages.push(['link-'+id, linkurl,type])
                      }
                    }                  
                    break;
                  }
                  case 'buttons':{
                    if(widget.buttons){
                      const buttons = widget.buttons
                      buttons.forEach((btn, indexBtn) => {
                        if(btn.url){
                          let img = deepCopy(supportUrlStructure(btn.url, passId,this.settingsService.contextId$))
                          // btn.url = img
                          if(img){
                            imagesToExport[id+'.'+indexBtn] = img
                            exportImages.push([id+'.'+indexBtn, img,type])
                          }
                        }
                      });
                    }
                    break;
                  }
                  case 'annotatedMap':{
                    if(widget.annotations){
                      const annotations = widget.annotations
                      annotations.forEach((annotation, indexPin) => {
                        if(annotation.linkURL){
                          let linkurl = deepCopy(supportUrlStructure(annotation.linkURL, passId,this.settingsService.contextId$))
                          // annotation.linkURL = linkurl
                          if(linkurl){
                            imagesToExport['link-'+id+'.'+indexPin] = linkurl
                            exportImages.push(['link-'+id+'.'+indexPin, linkurl,type])
                          }
                        }
                      });
                    }
                    break;
                  }
                  case 'video':{
                    if(widget.url){
                      const img = deepCopy(supportUrlStructure(widget.url, passId,this.settingsService.contextId$))
                      if(img){
                        imagesToExport[id] = img
                        exportImages.push([id, img,type])
                      }
                    }
                    break;
                  }
                  case 'callToAction':{
                    if(widget.imageUrl){
                      let img =  deepCopy(supportUrlStructure(widget.imageUrl, passId,this.settingsService.contextId$))
                      // widget.imageUrl = img
                      if(img){
                        imagesToExport[id] = img
                        exportImages.push([id, img,type])
                      }
                    }
                    break;
                  }
                  case 'splashVideo':{
                    if(widget.url){
                      const img = deepCopy(supportUrlStructure(widget.url, passId,this.settingsService.contextId$))
                      if(img){
                        imagesToExport[id] = img
                        // widget.url = img
                        exportImages.push([id, img,type])
                      }
                    }
                    break;
                  }
  
                  case 'spacer':{
                    break;
                  }
  
                  case 'form':{
                    break;
                  }
  
                  case 'list':{
                    break;
                  }
  
                  case 'products':{
                    break;
                  }
  
                  case '3dmodel':{
                    const img = deepCopy(supportUrlStructure(widget.url, passId,this.settingsService.contextId$))
                    if(img){
                      imagesToExport[id] = img
                      exportImages.push([id, img,type])
                    }
                    break;
                  }

                  case 'countdown':{
                    if(widget.targetDate){
                      const date = new Date( isDateTimeGetTimeSeconds(widget.targetDate))
                      widget.targetDate = date
                      const seconds = Math.floor(date.getTime() / 1000);
                      const nanoseconds = (date.getMilliseconds() * 1000000);
                      const timestampJson = {
                        _seconds: seconds,_nanoseconds: nanoseconds
                      }
                      widget.targetDate = timestampJson
                    }
                    break;
                  }

                  case 'composed':{
                    break;
                  }

                  case 'productRegistration':{
                    if(widget.image && widget.image.url){
                      const img = deepCopy(supportUrlStructure(widget.image.url, passId,this.settingsService.contextId$))
                      if(img){
                        imagesToExport[id] = img
                        exportImages.push([id, img,type])
                      }
                    }
                  }
                }
              });
            }
  
            if(value.theme){
              if(value.theme.fonts){
                const fonts = Object.entries(value.theme.fonts)
                fonts.forEach( font=> {
                  const name = font[0]
                  const fontData = font[1]
                  if(fontData && fontData['url']){
                    let url = deepCopy(supportUrlStructure(fontData['url'], passId,this.settingsService.contextId$))
                    if(url){
                      imagesToExport['font-'+name] = url
                      exportImages.push(['font-'+name, url, 'fonts'])
                    }
                  }
                })
              }
            }
  
            const entries = Object.entries(value)
            let data = {}
  
            entries.forEach(entry => {
              if(entry[1] && entry[1] != null)
                data[entry[0]] = entry[1]
            })
            removeUndefined(imagesToExport)
            this.exportFilesToZip(data, exportImages, data, row)
          }
        // }catch(error){}
      })
    }
  }


  //------not used right now---> changes to exportFilesToZip()
  exportToZip(data, imagesToExport){
    try{
      const zip = new JSZip();
      let name = 'template_export'
      if(data.name){
        name = data.name.replace(" ", '_')
      }

      zip.file("template.json", JSON.stringify(data));

      if (imagesToExport){
        const images = imagesToExport
        const length = images.length
        let images_length=0
        let index = 0
        images.forEach(async (img, indexImg) => {
          images_length++
          const fileName = img[0]
          const url:string = img[1]+''
          this.commentService.progressSpin.emit(true)

          if(url){
            let imgData 
            await this.http.get(url, { responseType: 'blob' }).subscribe(blob => {
              // Create a link element
              index++
              this.commentService.progressSpin.emit(true)
              let linkurl = ''
              linkurl= URL.createObjectURL(blob);

              imgData = linkurl
              if(imgData){
                zip.file(fileName, imgData, { base64: true });
              
                if(index == images_length ){
                  this.commentService.progressSpin.emit(false)

                  zip.generateAsync({type:"blob"}).then(function(content) {
                    // see FileSaver.js
                    saveAs(content, name+"(1.2.7_).nvpt");
                  });
                }
              }

            }, error => {
              index++
              console.error('Error downloading the image: ', error);
              this.commentService.progressSpin.emit(false)
              if(indexImg === images_length ){
                zip.generateAsync({type:"blob"}).then(function(content) {
                  saveAs(content, name+"(1.2.6_).nvpt");
                });
              }
            });
          }

        });
      }else{
        zip.generateAsync({type:"blob"}).then(function(content) {
          // see FileSaver.js
          saveAs(content, name+"(1.2.3).nvpt");
          this.commentService.progressSpin.emit(false)
        });
      }
    }catch(error){
      this.commentService.progressSpin.emit(false)
    }
  }

  exportFilesToZip(data, imagesToexport, passTemplate, contentype){
    try{
      var zip = new JSZip;
      let nameZIP = 'template_export'
      if(data.name){
        nameZIP = data.name.replaceAll(" ", '_')
      }
        this.commentService.progressSpin.emit(true)
        removeUndefined(passTemplate)
        removeUndefined(contentype)
        zip.file("template.json", JSON.stringify(passTemplate));
        if(contentype.advancedExperienceId)
          delete contentype.advancedExperienceId

        if(contentype.coverImage)
          delete contentype.coverImage
        
        if(contentype.nfcURLPrefix)
          delete contentype.nfcURLPrefix
        
        zip.file("contentType.json", JSON.stringify(contentype));

      if(imagesToexport && imagesToexport.length!=0){
        let allFIlestoAddZip = []
        let allfiles = -1
        let length = imagesToexport.length

        imagesToexport.forEach((img, indexImg )=> {
          const name = img[0]
          const type = img[2]
          const url = img[1]

          this.http.get(url, { responseType: 'blob' })
            .subscribe(x => {
              allfiles++;
              this.commentService.progressSpin.emit(true);

              allFIlestoAddZip.push([name, x]);
              zip.file(name, x);

              if (allfiles == length - 1) {
                zip.generateAsync({ type: "blob" })
                .then(blob => saveAs(blob, nameZIP+'(1.1).nvpt'));
                this.commentService.progressSpin.emit(false)
                const message = this.translate.instant('SnackBarConstants.ZIP_EXPORT_OK')
                this.commentService.addSnackBar.emit(message)
              }

            }, error => {
              this.commentService.progressSpin.emit(false)
              console.log(error)
              length--

              if (allfiles == length - 1) {
                zip.generateAsync({ type: "blob" })
                .then(blob => saveAs(blob, nameZIP+'(1.1_).nvpt'));
                this.commentService.progressSpin.emit(false)
                const message = this.translate.instant('SnackBarConstants.ZIP_EXPORT_OK')
                this.commentService.addSnackBar.emit(message)
              }
            });
        })
      }else{
        zip.generateAsync({ type: "blob" })
        .then(blob => saveAs(blob, nameZIP+'(1.1.0).nvpt'));
        this.commentService.progressSpin.emit(false)
        const message = this.translate.instant('SnackBarConstants.ZIP_EXPORT_OK')
        this.commentService.addSnackBar.emit(message)
      }

    }catch(error){
      console.log(error)
      const message = this.translate.instant('SnackBarConstants.ZIP_EXPORT_FAILED')
      this.commentService.addSnackBar.emit(message)
      this.commentService.progressSpin.emit(false)
    }
    
  }

  readURL(event){
    if (event.target.files && event.target.files[0]) {
      //this.commentService.progressSpin.emit(true)
      var reader = new FileReader();

      this.importedZip$.next(event.target.files[0])
      
      reader.readAsDataURL(event.target.files[0]);
      JSZip.loadAsync(event.target.files[0]).then((zip) => { // open zip and read files names
        this.importedFiles$.next(zip.files)
        const allfiles = Object.keys(zip.files)
        let newPassData 
        let newContentTypeData 
        let newPassDataID
        let newContentTypeID
        let filesTouploadSize = allfiles.length - 2
        let filesUrlLoaded = 0
        
        if(zip.files['template.json']){
          zip.files['template.json'].async('string').then((fileData) => { // template.json for passDefinition data
            newPassData = JSON.parse(fileData)
            const template = new Template
            template.setFromAny(newPassData)
            this.importContentypeAndPass(newContentTypeData, newPassData, zip.files)
          });
        }

        if(zip.files['contentType.json']){
          zip.files['contentType.json'].async('string').then((fileData) => { // contentType.json for contenType data
              newContentTypeData = JSON.parse(fileData)
              const template = new ContentType
              template.setFromAny(newContentTypeData)
              this.importContentypeAndPass(newContentTypeData, newPassData, zip.files)
          });
        }
        // const a = this.updateFilesNamesWithUrls(newPassData, Object.entries(zip.files), zip)
        
        this.importedPassNewID$.subscribe( value => {
          newPassDataID = value
          newPassData['id'] = value

          if(newPassDataID){
            Object.keys(zip.files).forEach((filename) => { // <----- HERE
              if(filename == 'template.json' || filename == 'contentType.json'){
              }else{
                filesUrlLoaded++
                const filesWName = this.updateFileNamesWithUrl(newPassData, zip.files[filename], zip, filename, filesUrlLoaded, filesTouploadSize)
              }
            });
          }
        })

        this.importedContentypeID$.subscribe( value => {
          newContentTypeID = value
          newPassData['contentType'] = value
        })        
      });
    }
  }


  async importContentypeAndPass(contenttype, passTemplate, files?){
    let id 
    if(contenttype &&  passTemplate){
      try{
        //this.commentService.progressSpin.emit(true)
        const newContent = await this.contenttypeService.importContentype(contenttype, passTemplate)
        const contypeId = newContent.contentTypeId
        const passid = newContent.passTemplateId
        if(passid){
          this.importedPassNewID$.next(passid)
        }

        if(contypeId){
          this.importedContentypeID$.next(contypeId)
        }
        this.settingsService.observeContextsContentTypesFirestoreWithId(this.settingsService.contextId$)

        //this.commentService.progressSpin.emit(false)
        const message = this.translate.instant("SnackBarConstants.IMPORT_OK")
        this.commentService.addSnackBar.emit(message)
      } catch(error){
        //this.commentService.progressSpin.emit(false)
        const message = this.translate.instant("SnackBarConstants.IMPORT_FAILED")
        this.commentService.addSnackBar.emit(message)
      }
    }else{
      const message = this.translate.instant('SnackBarConstants.ZIP_MISSING_FIELDS')
      this.commentService.addSnackBar.emit(message)
      //this.commentService.progressSpin.emit(false)
    }
  } 

  updateFilesNamesWithUrls(newPassData, files, zip){
    let alltransformed = false
    let filesName = {}
    if(newPassData){
      const passID = newPassData.id
      files.forEach( file => {
        const fileName = file[0]
      
        if(fileName != 'template.json' || fileName != 'contentType.json'){
          if(fileName.includes('font-')){
            if(newPassData.theme && newPassData.theme.fonts){
            }
          }else{
            if(newPassData.widgets){
              const widget = newPassData.widgets.find( widget => fileName.includes(widget.id))
              if(widget){
                if(fileName.includes('link-')){
                  switch(widget.type){
                    case 'billboardImage':{                
                      if(widget.linkURL){
                        const values = widget.linkURL.split("/")
                        const linkURL = values[values.length-1]
                        file.push(linkURL)
                        filesName[fileName] = file
                      }
                      break;
                    }
                    case 'billboardVideo':{
                      break;
                    }
                    case 'text':{                
                      if(widget.image && widget.image.linkURL){
                        const values = widget.image.linkURL.split("/")
                        const linkURL = values[values.length-1]
                        file.push(linkURL)
                        filesName[fileName] = file
                      }
                      break;
                    }
                
                    case 'imagesHorizontal':{
                      if(widget.images){
                        const images = widget.images
                        images.forEach((image,imageIndex) => {                
                          if(image.linkURL){
                            const values = image.linkURL.split("/")
                            const linkURL = values[values.length-1]
                            file.push(linkURL)
                            filesName[fileName] = file
                          }
                        });
                      }
                      break;
                    }
                    case 'imageBlocks':{
                      if(widget.images){
                        const images = widget.images
                        images.forEach((image,imageIndex) => {                
                          if(image.linkURL){
                            const values = image.linkURL.split("/")
                            const linkURL = values[values.length-1]
                            file.push(linkURL)
                            filesName[fileName] = file
                          }
                        });
                      }
                      break;
                    }
                
                    case 'images':{
                      if(widget.images){
                        const images = widget.images
                        images.forEach((image,imageIndex) => {                
                          if(image.linkURL){
                            const values = image.linkURL.split("/")
                            const linkURL = values[values.length-1]
                            file.push(linkURL)
                            filesName[fileName] = file
                          }
                        });
                      }
                      break;
                    }
                
                    case 'image':{
                      if(widget.linkURL){
                        const values = widget.linkURL.split("/")
                        const linkURL = values[values.length-1]
                        file.push(linkURL)
                        filesName[fileName] = file
                      }                  
                      break;
                    }
                    case 'buttons':{  
                      break;
                    }
                    case 'annotatedMap':{
                      if(widget.annotations){
                        const annotations = widget.annotations
                        annotations.forEach((annotation, indexPin) => {
                          if(annotation.linkURL){
                            if(fileName.includes(widget.id) && fileName.includes('.'+indexPin)){
                              const values = annotation.linkURL.split("/")
                              const linkURL = values[values.length-1]
                              file.push(linkURL)
                              filesName[fileName] = file
                            }
                          }
                        });
                      }
                      break;
                    }
                    case 'video':{
                      break;
                    }
                    case 'callToAction':{
                      break;
                    }
                    case 'splashVideo':{
                      break;
                    }
                
                    case 'spacer':{
                      break;
                    }
                
                    case 'form':{
                      break;
                    }
                
                    case 'list':{
                      break;
                    }
                
                    case 'products':{
                      break;
                    }
                
                    case '3dmodel':{
                  
                      break;
                    }
                  
                  }
                }else{
                  switch(widget.type){
                    case 'billboardImage':{
                      if(widget.url){
                        const values = widget.url.split("/")
                        const url = values[values.length-1]
                        file.push(url)
                        filesName[fileName] = file
                        //   var blob =  zip.file( file ).async("blob");
                        //   var nfile = new File( [blob], url );
                      }
                
                      if(widget.linkURL){}
              
                      break;
                    }
                    case 'billboardVideo':{
                      if(widget.url){
                        const values = widget.url.split("/")
                        const url = values[values.length-1]
                        file.push(url)
                        filesName[fileName] = file
                      }
                      break;
                    }
                    case 'text':{
                      if(widget.image && widget.image.url){
                        const values = widget.image.url.split("/")
                        const url = values[values.length-1]
                        file.push(url)
                        filesName[fileName] = file
                      }
                
                      if(widget.image && widget.image.linkURL){}
                      break;
                    }
                
                    case 'imagesHorizontal':{
                      if(widget.images){
                        const images = widget.images
                        images.forEach((image,imageIndex) => {
                          if(image.url){
                            const values = image.url.split("/")
                            const url = values[values.length-1]
                            file.push(url)
                            filesName[fileName] = file
                          }
                          if(image.linkURL){}
                        });
                      }
                      break;
                    }
                    case 'imageBlocks':{
                      if(widget.images){
                        const images = widget.images
                        images.forEach((image,imageIndex) => {
                          if(image.url){
                            if(fileName.includes(widget.id) && fileName.includes('.'+imageIndex)){
                              const values = image.url.split("/")
                              const url = values[values.length-1]
                              file.push(url)
                              filesName[fileName] = file
                            }
                          }
                
                          if(image.linkURL){}
                        });
                      }
                      break;
                    }
                
                    case 'images':{
                      if(widget.images){
                        const images = widget.images
                        images.forEach((image,imageIndex) => {
                          if(image.url){
                            if(fileName.includes(widget.id) && fileName.includes('.'+imageIndex)){
                              const values = image.url.split("/")
                              const url = values[values.length-1]
                              file.push(url)
                              filesName[fileName] = file
                            }
                          }
                
                          if(image.linkURL){}
                        });
                      }
                      break;
                    }
                
                    case 'image':{
                      if(widget.url){
                        const values = widget.url.split("/")
                        const url = values[values.length-1]
                        file.push(url)
                        filesName[fileName] = file
                      }
                      if(widget.linkURL){}                  
                      break;
                    }
                    case 'buttons':{
                      if(widget.buttons){
                        const buttons = widget.buttons
                        buttons.forEach((btn, indexBtn) => {
                          if(btn.url){
                            if(fileName.includes(widget.id) && fileName.includes('.'+indexBtn)){
                              const values = btn.url.split("/")
                              const url = values[values.length-1]
                              file.push(url)
                              filesName[fileName] = file
                            }
                          }
                        });
                      }
                      break;
                    }
                    case 'annotatedMap':{
                      break;
                    }
                    case 'video':{
                      if(widget.url){
                        const values = widget.url.split("/")
                        const url = values[values.length-1]
                        file.push(url)
                        filesName[fileName] = file
                      }
                      break;
                    }
                    case 'callToAction':{
                      if(widget.imageUrl){
                        const values = widget.imageUrl.split("/")
                        const url = values[values.length-1]
                        file.push(url)
                        filesName[fileName] = file
                      }
                      break;
                    }
                    case 'splashVideo':{
                      if(widget.url){
                        const values = widget.url.split("/")
                        const url = values[values.length-1]
                        file.push(url)
                        filesName[fileName] = file
                      }
                      break;
                    }
                
                    case 'spacer':{
                      break;
                    }
                
                    case 'form':{
                      break;
                    }
                
                    case 'list':{
                      break;
                    }
                
                    case 'products':{
                      break;
                    }
                
                    case '3dmodel':{
                      const values = widget.url.split("/")
                      const url = values[values.length-1]
                      file.push(url)
                      filesName[fileName] = file
                      break;
                    }

                    case 'productRegistration':{
                      if(widget.image && widget.image.url){
                        const values = widget.image.url.split("/")
                        const url = values[values.length-1]
                        file.push(url)
                        filesName[fileName] = file
                      } 
                      break;
                    }
                  
                  }
                }
              }
              
            }
          }
        }
        this.importedPassFilesToUpload$.next(filesName)
      })
    }
    if(Object.keys(filesName).length == files.length -2){
      alltransformed = true
    }
    return [alltransformed, filesName]
  }

  updateFileNamesWithUrl(newPassData, zipfile, zip, filename, ilesUrlLoaded, filesTouploadSize){
    this.commentService.progressSpin.emit(true)
    let alltransformed = false
    let filesName = {}
    let files = zip.files
    let file = [zipfile]

    if(newPassData){
      const passID = newPassData.id

      const fileName = filename

      if(fileName != 'template.json' && fileName != 'contentType.json'){
        if(fileName.includes('font-')){
          const fonts = [ 'title', 'subtitle', 'plain','bold', 'italic'] 
          if(newPassData.theme && newPassData.theme.fonts){
            switch(fileName){
              case 'font-title':{
                if(newPassData.theme.fonts.title && newPassData.theme.fonts.title.url){
                  const values = newPassData.theme.fonts.title.url.split("/")
                  const url = values[values.length-1]
                  file.push(url)
                  filesName[fileName] = file
                }
                break;
              }
              case 'font-subtitle':{
                if(newPassData.theme.fonts.subtitle && newPassData.theme.fonts.subtitle.url){
                  const values = newPassData.theme.fonts.subtitle.url.split("/")
                  const url = values[values.length-1]
                  file.push(url)
                  filesName[fileName] = file
                }
                break;
              }

              case 'font-plain':{
                if(newPassData.theme.fonts.plain && newPassData.theme.fonts.plain.url){
                  const values = newPassData.theme.fonts.plain.url.split("/")
                  const url = values[values.length-1]
                  file.push(url)
                  filesName[fileName] = file
                }
                break;
              }

              case 'font-bold':{
                if(newPassData.theme.fonts.bold && newPassData.theme.fonts.bold.url){
                  const values = newPassData.theme.fonts.bold.url.split("/")
                  const url = values[values.length-1]
                  file.push(url)
                  filesName[fileName] = file
                }
                break;
              }
              case 'font-italic':{
                if(newPassData.theme.fonts.italic && newPassData.theme.fonts.italic.url){
                  const values = newPassData.theme.fonts.italic.url.split("/")
                  const url = values[values.length-1]
                  file.push(url)
                  filesName[fileName] = file
                }
                break;
              }
            }
          }
          
        }else{
          if(newPassData.widgets){
            const widget = newPassData.widgets.find( widget => fileName.includes(widget.id))
            if(widget){
              if(fileName.includes('link-')){
                switch(widget.type){
                  case 'billboardImage':{              
                    if(widget.linkURL){
                      const values = widget.linkURL.split("/")
                      const linkURL = values[values.length-1]
                      file.push(linkURL)
                      filesName[fileName] = file
                    }
                    break;
                  }
                  case 'billboardVideo':{
                    break;
                  }
                  case 'text':{              
                    if(widget.image && widget.image.linkURL){
                      const values = widget.image.linkURL.split("/")
                      const linkURL = values[values.length-1]
                      file.push(linkURL)
                      filesName[fileName] = file
                    }
                    break;
                  }
              
                  case 'imagesHorizontal':{
                    if(widget.images){
                      const images = widget.images
                      images.forEach((image,imageIndex) => {                
                        if(image.linkURL){
                          const values = image.linkURL.split("/")
                          const linkURL = values[values.length-1]
                          file.push(linkURL)
                          filesName[fileName] = file
                        }
                      });
                    }
                    break;
                  }
                  case 'imageBlocks':{
                    if(widget.images){
                      const images = widget.images
                      images.forEach((image,imageIndex) => {                
                        if(image.linkURL){
                          const values = image.linkURL.split("/")
                          const linkURL = values[values.length-1]
                          file.push(linkURL)
                          filesName[fileName] = file
                        }
                      });
                    }
                    break;
                  }
              
                  case 'images':{
                    if(widget.images){
                      const images = widget.images
                      images.forEach((image,imageIndex) => {                
                        if(image.linkURL){
                          const values = image.linkURL.split("/")
                          const linkURL = values[values.length-1]
                          file.push(linkURL)
                          filesName[fileName] = file
                        }
                      });
                    }
                    break;
                  }
              
                  case 'image':{
                    if(widget.linkURL){
                      const values = widget.linkURL.split("/")
                      const linkURL = values[values.length-1]
                      file.push(linkURL)
                      filesName[fileName] = file

                    }                
                    break;
                  }
                  case 'buttons':{
                    break;
                  }
                  case 'annotatedMap':{
                    if(widget.annotations){
                      const annotations = widget.annotations
                      annotations.forEach((annotation, indexPin) => {
                        if(annotation.linkURL){
                          if(fileName.includes(widget.id) && fileName.includes('.'+indexPin)){
                            const values = annotation.linkURL.split("/")
                            const linkURL = values[values.length-1]
                            file.push(linkURL)
                            filesName[fileName] = file

                          }
                        }
                      });
                    }
                    break;
                  }
                  case 'video':{
                    break;
                  }
                  case 'callToAction':{
                    break;
                  }
                  case 'splashVideo':{
                    break;
                  }
                  case 'spacer':{
                    break;
                  }
                  case 'form':{
                    break;
                  }
                  case 'list':{
                    break;
                  }
                  case 'products':{
                    break;
                  }
                  case '3dmodel':{
                    break;
                  }
                }
              }else{
                switch(widget.type){
                  case 'billboardImage':{
                    if(widget.url){
                      const values = widget.url.split("/")
                      const url = values[values.length-1]
                      file.push(url)
                      filesName[fileName] = file
                    }
              
                    if(widget.linkURL){}
                    break;
                  }
                  case 'billboardVideo':{
                    if(widget.url){
                      const values = widget.url.split("/")
                      const url = values[values.length-1]
                      file.push(url)
                      filesName[fileName] = file
                    }
                    break;
                  }
                  case 'text':{
                    if(widget.image && widget.image.url){
                      const values = widget.image.url.split("/")
                      const url = values[values.length-1]
                      file.push(url)
                      filesName[fileName] = file
                    }
                    if(widget.image && widget.image.linkURL){}
                    break;
                  }
            
                  case 'imagesHorizontal':{
                    if(widget.images){
                      const images = widget.images
                      images.forEach((image,imageIndex) => {
                        if(image.url){
                          const values = image.url.split("/")
                          const url = values[values.length-1]
                          file.push(url)
                          filesName[fileName] = file
                        }
                        if(image.linkURL){}
                      });
                    }
                    break;
                  }
                  case 'imageBlocks':{
                    if(widget.images){
                      const images = widget.images
                      images.forEach((image,imageIndex) => {
                        if(image.url){
                          if(fileName.includes(widget.id) && fileName.includes('.'+imageIndex)){
                            const values = image.url.split("/")
                            const url = values[values.length-1]
                            file.push(url)
                            filesName[fileName] = file
                          }
                        }
                        if(image.linkURL){}
                      });
                    }
                    break;
                  }
            
                  case 'images':{
                    if(widget.images){
                      const images = widget.images
                      images.forEach((image,imageIndex) => {
                        if(image.url){
                          if(fileName.includes(widget.id) && fileName.includes('.'+imageIndex)){
                            const values = image.url.split("/")
                            const url = values[values.length-1]
                            file.push(url)
                            filesName[fileName] = file
                          }
                        }
                        if(image.linkURL){}
                      });
                    }
                    break;
                  }
            
                  case 'image':{
                    if(widget.url){
                      const values = widget.url.split("/")
                      const url = values[values.length-1]
                      file.push(url)
                      filesName[fileName] = file
                    }
                    if(widget.linkURL){}                
                    break;
                  }
                  case 'buttons':{
                    if(widget.buttons){
                      const buttons = widget.buttons
                      buttons.forEach((btn, indexBtn) => {
                        if(btn.url){
                          if(fileName.includes(widget.id) && fileName.includes('.'+indexBtn)){
                            const values = btn.url.split("/")
                            const url = values[values.length-1]
                            file.push(url)
                            filesName[fileName] = file
                          }
                        }
                      });
                    }
                    break;
                  }
                  case 'annotatedMap':{
                    break;
                  }
                  case 'video':{
                    if(widget.url){
                      const values = widget.url.split("/")
                      const url = values[values.length-1]
                      file.push(url)
                      filesName[fileName] = file
                    }
                    break;
                  }
                  case 'callToAction':{
                    if(widget.imageUrl){
                      const values = widget.imageUrl.split("/")
                      const url = values[values.length-1]
                      file.push(url)
                      filesName[fileName] = file
                    }
                    break;
                  }
                  case 'splashVideo':{
                    if(widget.url){
                      const values = widget.url.split("/")
                      const url = values[values.length-1]
                      file.push(url)
                      filesName[fileName] = file
                    }
                    break;
                  }
            
                  case 'spacer':{
                    break;
                  }
                  case 'form':{
                    break;
                  }
                  case 'list':{
                    break;
                  }
                  case 'products':{
                    break;
                  }
                  case '3dmodel':{
                    const values = widget.url.split("/")
                    const url = values[values.length-1]                    
                    file.push(url)
                    filesName[fileName] = file
                    break;
                  }

                  case 'productRegistration':{
                    if(widget.image && widget.image.url){
                      const values = widget.image.url.split("/")
                      const url = values[values.length-1]
                      file.push(url)
                      filesName[fileName] = file
                    }
                    break;
                  }
                }
              }
            }
          }
        }
      }

      if(filesName){
        zip.files[filename].async('blob').then((fileData) => {
          try{
            const newfilename = filesName[filename][1]
            var nfile = new File( [fileData], newfilename );
            const upload = this.uploadService.uploadimportedImage(newPassData.id, nfile, newfilename,  ilesUrlLoaded, filesTouploadSize)
            this.commentService.progressSpin.emit(false)
          }catch(error){
            this.commentService.progressSpin.emit(false)
          }
        })
      }
      this.importedPassFilesToUpload$.next(filesName)
    }
    return  filesName
  }

  showCommentsOnList(row){
    let comment
    if(row && row.passTemplateId){
      if(this.allPassDefinitions){
        const pass = this.allPassDefinitions.find( pass => pass.id === row.passTemplateId)
        if(pass && pass.comment)
          comment = pass.comment
      }
    }
    return comment
  }

  showNameOnList(row){
    let templateName
    let passName
    if(row && row.passTemplateId){
      if(this.allPassDefinitions){
        const pass = this.allPassDefinitions.find( pass => pass.id === row.passTemplateId)
        if(pass && pass.templateName){
          templateName = pass.templateName
          return templateName
        }
        else if (pass && pass.name){
          passName = pass.name
          return passName
        }
      }
    }
  }

}


